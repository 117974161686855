@import "~bootstrap/scss/bootstrap";

:root {
  --el-font-size-base: 1rem;
  .el-input--large {
	font-size: 1rem;
  }
}

$main-color: #0e2f65;
$secondary-color: #ffcc00;

body {
  background-color: #f4f5f7;
}

#app {

}

.mh-95 {
  min-height: 95vh;
}

.flex-1 {
  flex: 1;
}

.dev-size-panel {
  position: fixed;
  left: 3px;
  top: 0;
  color: orangered;
  font-weight: bold;
  z-index: 1040;
}

.card.card-rounded {
  border-radius: 15px;
  box-shadow: none;
  border: none;
}

.login-form form ul.error {
  padding-left: 0;
  border-bottom: 1px solid #ff4500;
  padding-bottom: 10px;

  li {
	list-style: none;
  }
}

.gate {
  font-size: 1rem;

  &-name {
	background-color: #44c12f;
	color: #fff;
	padding: 0 3px;
  }

  &-bg {
	background-color: #fff;
	border: 1px solid #e4e4e4;
	border-radius: 15px;
	padding: 10px;
	display: inline-block;
  }
}

.datagrid .datagrid-toolbar {
  gap: 4px;
  display: inline-flex !important;
}

.el-tabs__item {
  font-size: 1rem !important;
}

html {
  scroll-padding-top: 4.5rem;
}

body {
  position: relative;
  overflow-x: hidden;
}

p {
  line-height: 1.75;
}

.page-section {
  padding: 2.5rem 0;
}

.page-section h2.section-heading, .page-section .section-heading.h2 {
  font-size: 2.5rem;
  margin-top: 0;
  margin-bottom: 1rem;
}

.page-section h3.section-subheading, .page-section .section-subheading.h3 {
  font-size: 1rem;
  font-weight: 400;
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  margin-bottom: 4rem;
}

@media (min-width: 768px) {
  section {
	padding: 9rem 0;
  }
}

.btn-xl {
  padding: 1.25rem 2.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 700;
}

.btn-social {
  height: 2.5rem;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 100%;
}

#mainNav {
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: $main-color;
}

#mainNav .navbar-toggler {
  padding: 0.75rem;
  font-size: 1rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  text-transform: uppercase;
  font-weight: 700;
}

#mainNav .navbar-brand {
  color: $secondary-color;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  letter-spacing: 0.0625em;
  text-transform: uppercase;
}

#mainNav .navbar-brand img {
  height: 1.5rem;
}

#mainNav .navbar-nav .nav-item .nav-link {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.2rem;
  color: #fff;
  letter-spacing: 0.0625em;
  transition: font-size 0.3s ease-in-out;
}

#mainNav .navbar-nav .nav-item .nav-link.active, #mainNav .navbar-nav .nav-item .nav-link:hover {
  color: $secondary-color;
}

@media (min-width: 992px) {
  #mainNav {
	padding-top: 1.5rem;
	padding-bottom: 1.5rem;
	border: none;
	background-color: $main-color;
	transition: padding-top 0.3s ease-in-out, padding-bottom 0.3s ease-in-out;
  }
  #mainNav .navbar-brand {
	font-size: 1.5em;
	transition: font-size 0.3s ease-in-out;
  }
  #mainNav .navbar-brand img {
	height: 2rem;
	transition: height 0.3s ease-in-out;
  }
  #mainNav.navbar-shrink {
	padding: 0.5rem 0;
	background-color: $main-color;
	opacity: 1;
  }

  #mainNav.navbar-shrink .navbar-nav .nav-item .nav-link {
	font-size: 1rem;
  }

  #mainNav.navbar-shrink .navbar-brand {
	font-size: 1.25em;
  }
  #mainNav.navbar-shrink .navbar-brand svg,
  #mainNav.navbar-shrink .navbar-brand img {
	height: 1.5rem;
  }
  #mainNav .navbar-nav .nav-item {
	margin-right: 1rem;
  }
  #mainNav .navbar-nav .nav-item:last-child {
	margin-right: 0;
  }
}

#portfolio .portfolio-item {
  max-width: 26rem;
  margin-left: auto;
  margin-right: auto;
}

#portfolio .portfolio-item .portfolio-link {
  position: relative;
  display: block;
  margin: 0 auto;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 200, 0, 0.9);
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity ease-in-out 0.25s;
}

#portfolio .portfolio-item .portfolio-link .portfolio-hover .portfolio-hover-content {
  font-size: 1.25rem;
  color: white;
}

#portfolio .portfolio-item .portfolio-link:hover .portfolio-hover {
  opacity: 1;
}

#portfolio .portfolio-item .portfolio-caption {
  padding: 1.5rem;
  text-align: center;
  background-color: #fff;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-heading {
  font-size: 1.5rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  margin-bottom: 0;
}

#portfolio .portfolio-item .portfolio-caption .portfolio-caption-subheading {
  font-style: italic;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-dialog {
  margin: 1rem;
  max-width: 100vw;
}

.portfolio-modal .modal-content {
  padding-top: 6rem;
  padding-bottom: 6rem;
  text-align: center;
}

.portfolio-modal .modal-content h2, .portfolio-modal .modal-content .h2 {
  font-size: 3rem;
  line-height: 3rem;
}

.portfolio-modal .modal-content p.item-intro {
  font-style: italic;
  margin-bottom: 2rem;
  font-family: "Roboto Slab", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.portfolio-modal .modal-content p {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content ul.list-inline {
  margin-bottom: 2rem;
}

.portfolio-modal .modal-content img {
  margin-bottom: 2rem;
}

.portfolio-modal .close-modal {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
  background-color: transparent;
}

.portfolio-modal .close-modal:hover {
  opacity: 0.3;
}

.timeline {
  position: relative;
  padding: 0;
  list-style: none;
}

.timeline:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 40px;
  width: 2px;
  margin-left: -1.5px;
  content: "";
  background-color: #e9ecef;
}

.timeline > li {
  position: relative;
  min-height: 50px;
  margin-bottom: 50px;
}

.timeline > li:after, .timeline > li:before {
  display: table;
  content: " ";
}

.timeline > li:after {
  clear: both;
}

.timeline > li .timeline-panel {
  position: relative;
  float: right;
  width: 100%;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li .timeline-image {
  position: absolute;
  z-index: 100;
  left: 0;
  width: 80px;
  height: 80px;
  margin-left: 0;
  text-align: center;
  color: white;
  border: 7px solid #e9ecef;
  border-radius: 100%;
  background-color: $secondary-color;
}

.timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
  font-size: 10px;
  line-height: 14px;
  margin-top: 12px;
}

.timeline > li.timeline-inverted > .timeline-panel, .timeline li li > div.timeline-inverted > .timeline-panel {
  float: right;
  padding: 0 20px 0 100px;
  text-align: left;
}

.timeline > li.timeline-inverted > .timeline-panel:before, .timeline li > div.timeline-inverted > .timeline-panel:before {
  right: auto;
  left: -15px;
  border-right-width: 15px;
  border-left-width: 0;
}

.timeline > li.timeline-inverted > .timeline-panel:after, .timeline li > div.timeline-inverted > .timeline-panel:after {
  right: auto;
  left: -14px;
  border-right-width: 14px;
  border-left-width: 0;
}

.timeline > li:last-child {
  margin-bottom: 0;
}

.timeline .timeline-heading h4, .timeline .timeline-heading .h4 {
  margin-top: 0;
  color: inherit;
}

.timeline .timeline-heading h4.subheading, .timeline .timeline-heading .subheading.h4 {
  text-transform: none;
}

.timeline .timeline-body > ul,
.timeline .timeline-body > p {
  margin-bottom: 0;
}

@media (min-width: 768px) {
  .timeline:before {
	left: 50%;
  }
  .timeline > li {
	min-height: 100px;
  }
  .timeline > li .timeline-panel {
	float: left;
	width: 41%;
	padding: 0 20px 20px 30px;
	text-align: right;
  }
  .timeline > li .timeline-image {
	left: 50%;
	width: 100px;
	height: 100px;
	margin-left: -50px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
	font-size: 13px;
	line-height: 18px;
	margin-top: 16px;
  }
  .timeline > li.timeline-inverted > .timeline-panel, .timeline li > div.timeline-inverted > .timeline-panel {
	float: right;
	padding: 0 30px 20px 20px;
	text-align: left;
  }
}

@media (min-width: 992px) {
  .timeline > li {
	min-height: 150px;
  }
  .timeline > li .timeline-panel {
	padding: 0 20px 20px;
  }
  .timeline > li .timeline-image {
	width: 150px;
	height: 150px;
	margin-left: -75px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
	font-size: 18px;
	line-height: 26px;
	margin-top: 30px;
  }
  .timeline > li.timeline-inverted > .timeline-panel, .timeline li > div.timeline-inverted > .timeline-panel {
	padding: 0 20px 20px;
  }
}

@media (min-width: 1200px) {
  .timeline > li {
	min-height: 170px;
  }
  .timeline > li .timeline-panel {
	padding: 0 20px 20px 100px;
  }
  .timeline > li .timeline-image {
	width: 170px;
	height: 170px;
	margin-left: -85px;
  }
  .timeline > li .timeline-image h4, .timeline > li .timeline-image .h4 {
	margin-top: 40px;
  }
  .timeline > li.timeline-inverted > .timeline-panel, .timeline li > div.timeline-inverted > .timeline-panel {
	padding: 0 100px 20px 20px;
  }
}

.team-member {
  margin-bottom: 3rem;
  text-align: center;
}

.team-member img {
  width: 14rem;
  height: 14rem;
  border: 0.5rem solid rgba(0, 0, 0, 0.1);
}

.team-member h4, .team-member .h4 {
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.img-brand {
  height: 2.75rem;
}

section#contact {
  background-color: #212529;

  background-repeat: no-repeat;
  background-position: center;
}

section#contact .section-heading {
  color: #fff;
}

section#contact form#contactForm .form-group {
  margin-bottom: 1.5rem;
}

section#contact form#contactForm .form-group input,
section#contact form#contactForm .form-group textarea {
  padding: 1.25rem;
}

section#contact form#contactForm .form-group input.form-control {
  height: auto;
}

section#contact form#contactForm .form-group-textarea {
  height: 100%;
}

section#contact form#contactForm .form-group-textarea textarea {
  height: 100%;
  min-height: 10rem;
}

section#contact form#contactForm p.help-block {
  margin: 0;
}

section#contact form#contactForm .form-control:focus {
  border-color: $secondary-color;
  box-shadow: none;
}

section#contact form#contactForm ::-webkit-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm ::-moz-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

section#contact form#contactForm :-ms-input-placeholder {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  color: #ced4da;
}

.footer {
  text-align: center;
  font-size: 0.9rem;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.header-logo-wrapper {
  display: flex;
  justify-content: center;
}

.fade-opacity {
  transition: opacity 0.5s !important;
}

.pointer-none {
  pointer-events: none
}

#mainNav .navbar-toggler {
  color: $secondary-color;
  border-color: $secondary-color;
  border-width: 2px;

}

.delimiter-main, .delimiter-second {
  background-image: url("../Images/delimiter-main.svg");
  background-repeat: repeat-x;
  height: 50px;
  margin: 25px 0;
}

.delimiter-second {
  background-image: url("../Images/delimiter-second.svg");
}

.navbar.navbar-shrink:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -17px;
  width: 100%;
  height: 50px;
  background-image: url("../Images/delimiter-multi.svg");
  background-repeat: repeat-x;
  z-index: -1;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 3px solid $main-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

#map .list-group-item {
  background-color: transparent;
}

.program-box-1, .program-box-2, .program-box-3, .program-box-4, .program-box-5, .program-box-6, .program-box-7, .program-box-8, .program-box-9,
{
  width: 5px;
  height: 35px;
}

.program-box-1 {
  background-color: #e10327;
}

.program-box-2 {
  background-color: #009f3e;
}

.program-box-3 {
  background-color: #cba960;
}

.program-box-4 {
  background-color: #7f007b;
}

.program-box-5 {
  background-color: #3d107b;
}

.program-box-6 {
  background-color: #f6d601;
}

.program-box-7 {
  background-color: #e3007a;
}

.program-box-8 {
  background-color: #ec8612;
}

.program-box-9 {
  background-color: #00a8ed;
}

.timeline div.toggle-button.collapsed .fa-chevron-up {
  display: none;
}

.timeline div.toggle-button[aria-expanded="true"] {
  margin-top: 0.5rem;
}

.timeline div.toggle-button[aria-expanded="true"] .fa-chevron-down {
  display: none;

}

@include media-breakpoint-up(lg) {
  .header-logo-wrapper {
  }

  .h-lg-100 {
	height: 100%;
  }

  .border-lg-start {
	border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}

@include media-breakpoint-up(md) {
  .border-md-start {
	border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
  }
}

.content-wrapper {
  margin: 16px auto;
  padding: calc(var(--bs-gutter-x)* 1.3);
  border-radius: 15px;
  border: 1px dashed #258114;
}
.header-text {
  position: absolute;
  bottom: 20px;
  left: 30px;
  background-color: #ffffffe3;
  padding: 10px 15px;
  border-radius: 10px;
}

.text-justify {
  text-align: justify;
}

.el-tag, .el-form-item__label, .el-input__inner, .el-button--large, .el-form-item__content
{
  font-size: 1rem !important;
}

.calendar-list {
  .event-state, .vuecal__event-time {
    display: none;
  }

  .el-tabs__item {
    font-size: 0.8rem !important;
    padding: 0 10px;
  }

  @include media-breakpoint-up(md) {
    .event-state, .vuecal__event-time {
      display: block;
    }

    .el-tabs__item {
      font-size: 1rem !important;
      padding: 0 20px;
    }
  }
}

.right-0 {
  right: 0;
}

.event-editor {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  color: #333;
  gap: 8px;
}

.delete-button {
  position: absolute;
  right: 10px;
}


.vuecal__menu, .vuecal__cell-events-count {
  background-color: #cb0dff;
}

.vuecal__title-bar {
  background-color: #ff9400;
}

.vuecal__cell--today, .vuecal__cell--current {
  background-color: rgba(0, 0, 255, 0.4);
}

.vuecal:not(.vuecal--day-view) .vuecal__cell--selected {
  background-color: rgba(244, 245, 247, 0);
}

.vuecal__cell--selected:before {
  border-color: rgb(223, 223, 224);
}

/* Cells and buttons get highlighted when an event is dragged over it. */
.vuecal__cell--highlighted:not(.vuecal__cell--has-splits),
.vuecal__cell-split--highlighted {
  background-color: rgba(220, 6, 38, 0.5);
}

.vuecal__arrow.vuecal__arrow--highlighted,
.vuecal__view-btn.vuecal__view-btn--highlighted {
  background-color: rgba(255, 67, 0, 0.25);
}

.vuecal__event {
  background-color: steelblue;
  color: white;
  border-bottom: 1px solid #e5e6e7;
  position: relative;
}

.vuecal__event.block-event {
  background: repeating-linear-gradient(45deg, transparent, transparent 10px, #f2f2f2 10px, #f2f2f2 20px); /* IE 10+ */
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: xxx-large;
}

.vuecal__event.block-event .vuecal__event-time {
  display: none;
  align-items: center;
}

.btn-group-cal {
  margin-bottom: 15px;
}

.vuecal__event-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 5px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.vuecal__header {
  display: none;
}

.vuecal__cell-events .event-unconfirmed {
  background-color: #ff9400;
}

.vuecal__cell-events .event-confirmed {
  background-color: #44c12f;
}
